import Decimal from '@st/decimal'
import { useBonusesDeps } from '../useDeps'

interface RollingBonusData {
  rollingMultiplier: string
  rollingPeriodInHours: number | null
  rollingMinBetRate: string | null
  rollingMaxBetRate: string | null
  rollingCasinoCategoryIds: number[] | null
}

export function useRollingData(bonus: Ref<RollingBonusData>) {
  const { t, locale } = useI18n()
  const { format: formatMultiplier } = useNumberFormatter()

  const rollingMultiplier = computed(() =>
    new Decimal(bonus.value.rollingMultiplier).greaterThan(0) ||
    new Decimal(bonus.value.rollingMultiplier).lessThan(0)
      ? formatMultiplier(bonus.value.rollingMultiplier)
      : '',
  )

  const rollingDuration = computed(() => {
    const timeInHours = bonus.value.rollingPeriodInHours

    if (!timeInHours) return ''

    const hours = timeInHours % 24
    const days = (timeInHours - hours) / 24

    if (hours === 0) return `${t('bonuses.days', days)}`

    if (days === 0) return `${t('bonuses.hours', hours)}`

    return `${t('bonuses.days', days)} ${t('bonuses.hours', hours)}`
  })

  const { getCategoryNameById } = useBonusesDeps()

  const filteredCategoriesNames = computed(
    () =>
      bonus.value.rollingCasinoCategoryIds
        ?.map((id) => getCategoryNameById(id) ?? '')
        .filter((name) => !!name),
  )
  const listFormatter = new Intl.ListFormat(locale.value, {
    style: 'long',
    type: 'conjunction',
  })

  const formattedRollingMinBetRate = computed(() =>
    bonus.value.rollingMinBetRate
      ? formatMultiplier(bonus.value.rollingMinBetRate)
      : '',
  )
  const formattedRollingMaxBetRate = computed(() =>
    bonus.value.rollingMaxBetRate
      ? formatMultiplier(bonus.value.rollingMaxBetRate)
      : '',
  )

  const rollingRateMessage = computed(() => {
    let betMessage = ''
    let casinoMessage = ''

    if (
      !formattedRollingMinBetRate.value &&
      !formattedRollingMaxBetRate.value
    ) {
      betMessage = ''
    }

    if (filteredCategoriesNames.value?.length) {
      casinoMessage = t('bonuses.casinoRolling', {
        categories: listFormatter.format(filteredCategoriesNames.value),
      })
    }

    if (formattedRollingMinBetRate.value && !formattedRollingMaxBetRate.value) {
      betMessage = t('bonuses.betRateMin2', {
        min: formatMultiplier(formattedRollingMinBetRate.value),
      })
    }

    if (formattedRollingMaxBetRate.value && !formattedRollingMinBetRate.value) {
      betMessage = t('bonuses.betRateMax2', {
        max: formatMultiplier(formattedRollingMaxBetRate.value),
      })
    }

    if (formattedRollingMaxBetRate.value && formattedRollingMinBetRate.value) {
      betMessage = t('bonuses.betRateRange2', {
        min: formatMultiplier(formattedRollingMinBetRate.value),
        max: formatMultiplier(formattedRollingMaxBetRate.value),
      })
    }

    const cases = `${casinoMessage} ${betMessage ? `, ${betMessage}` : ''}`

    return betMessage && casinoMessage
      ? t('bonuses.rollingInCases', { cases })
      : ''
  })

  return {
    rollingMultiplier,
    rollingDuration,
    rollingRateMessage,
    filteredCategoriesNames,
    formattedRollingMinBetRate,
    formattedRollingMaxBetRate,
  }
}
